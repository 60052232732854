<template>
	<components-empty>
		<header class="scan-header">
			<div class="container py-5">
				<div class="align-items-center">
					<common-button-back :redirect="buttonBack" />
				</div>
			</div>
		</header>
		<div class="d-flex flex-column position-relative scan-body h-100">
			<qr-stream @decode="onDecode" @rejectionHandled="rejectionHandled" @init="onInit" track="undefined" :camera="camera">
				<div style="color: red;" class="frame"></div>
			</qr-stream>
		</div>
		<footer class="footer mt-auto py-3 bg-white">
			<div class="container text-center">
				<div class="pb-3" v-if="hasCode">
					<button type="button" class="btn btn-danger w-100" @click="leave">Ya no estoy en {{ description }}</button>
				</div>
				<small class="text-muted" v-else>Apuntá la cámara hacia un código Wantti, podés encontrarlos en mesas, puertas, mostradores ¡y más!</small>
			</div>
		</footer>
	</components-empty>
</template>

<script>
	import { ref, computed } from 'vue';
	import store from '@/store';
	import router from '@/router';
	import { QrStream } from 'vue3-qr-reader';
	import { useToast } from 'vue-toastification';
	import composableRedirect from '@/composables/redirect';

	export default {
		components: { QrStream },
		props: {
			buttonBack: Object
		},
		setup() {
			const { redirectBack } = composableRedirect();
			const hasCode = computed(() => store.getters['code/isDefined']);
			const description = computed(() => store.getters['code/description']);
			const toast = useToast();
			const camera = ref('auto');
			const flash = ref(false);

			const rejectionHandled = () => {
				toast.error('No se pudo acceder a la cámara.');
			}

			const onDecode = (data) => {
				camera.value = 'off';

				const regex = /\/code\/(\w+)/;
				const match = regex.exec(data);

				if(match) return router.push({ path: match[1] });
				toast.warning('Código inválido.');
				camera.value = 'auto';
			}

			const onInit = async (promise) => {
				try {
					const { capabilities } = await promise;
					flash.value = !!capabilities.torch;
				}catch(error) {
					if(error.name === 'NotAllowedError') {
						toast.error('ERROR: Debes conceder los permisos necesarios.');
					}else if(error.name === 'NotFoundError') {
						toast.error('ERROR: No hay cámaras en éste dispositivo.');
					}else if(error.name === 'NotSupportedError') {
						toast.error('ERROR: No posee una conexión segura.');
					}else if(error.name === 'NotReadableError') {
						toast.error('ERROR: La cámara está en uso por otra aplicación.');
					}else if(error.name === 'OverconstrainedError') {
						toast.error('ERROR: La cámara no es compatible.');
					}else if(error.name === 'StreamApiNotSupportedError') {
						toast.error('ERROR: El entorno no es compatible.');
					}else if(error.name === 'InsecureContextError') {
						toast.error('ERROR: El acceso a la cámara debe realizarse en un sitio seguro.');
					}else{
						toast.error('ERROR: No se pudo acceder a la cámara.');
					}

					redirectBack();
				}
			}

			const leave = () => store.dispatch('code/setCode', null).then(redirectBack);

			return { hasCode, description, rejectionHandled, onDecode, leave, onInit, flash };
		}
	}
</script>